import {
  IHouseStudentStripe,
  IMatch,
  IResponseShort,
  IBaseMatchData,
  TMatchData
} from "interfaces/interfaces";
import { ServiceApi } from "../utils/utilAmplify";
import query from "../utils/query";
import { ServiceName } from "../config/amplify";

class StudentAPIClass {
  private readonly api: ServiceApi;
  private readonly housesApi: ServiceApi;

  constructor() {
    this.api = new ServiceApi(ServiceName.StudentMatches);
    this.housesApi = new ServiceApi(ServiceName.HostHouse);
  }
  available(
    userId: string,
    orderBy: string,
    ascending: boolean,
    nextBatchToken?: string
  ): Promise<{ items: IHouseStudentStripe[]; next_batch_token?: string }> {
    return this.housesApi.get(
      "available?" +
        query.stringify({
          user_id: userId,
          order_by: orderBy,
          ascending,
          next_batch_token: nextBatchToken
        })
    );
  }
  getMatches(userId: string): Promise<TMatchData[]> {
    return this.api.get(`student-matches/${userId}`);
  }
  acceptInvite(payload: IBaseMatchData): Promise<IResponseShort> {
    return this.api.post("accept-invite", payload);
  }
  rejectInvite(payload: IBaseMatchData): Promise<IResponseShort> {
    return this.api.post("reject-invite", payload);
  }
  getMatch(
    studentId: string,
    hostId: string,
    houseId: string,
    options?: { showNotificationOnError?: boolean }
  ): Promise<IMatch> {
    return this.api
      .get(
        "details?" +
          query.stringify({
            student_id: studentId,
            host_id: hostId,
            house_id: houseId
          }),
        options
      )
      .then((res) => res.data);
  }
  showInterest(
    payload: IBaseMatchData,
    message: string
  ): Promise<IResponseShort> {
    return this.api.post("show-interest", {
      student_id: payload.student_id,
      host_id: payload.host_id,
      house_id: payload.house_id,
      message
    });
  }
}

export const StudentAPI = new StudentAPIClass();

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IMatch, IBaseMatchData } from "../../interfaces/interfaces";
import { HostAPI } from "../hostApi";
import { getMatchQueryKey } from "./studentApiQueries";
import { getHouseQueryKey, getHousesQueryKey } from "./houseApiQueries";
import { useContextHospi } from "../../context/ContextHospi";

export const useSendInvite = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    Required<
      Pick<
        IMatch,
        | "host_id"
        | "house_id"
        | "student_id"
        | "student_check_in_date"
        | "student_check_out_date"
      >
    >
  >({
    mutationFn: (payload) => HostAPI.invite(payload),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: getMatchQueryKey({
          studentId: variables.student_id,
          hostId: variables.host_id,
          houseId: variables.house_id
        })
      });
    }
  });
};
export const useCancelInvite = () => {
  const queryClient = useQueryClient();
  return useMutation<unknown, unknown, IBaseMatchData>({
    mutationFn: (payload) => HostAPI.cancelInvite(payload),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: getMatchQueryKey({
          studentId: variables.student_id,
          hostId: variables.host_id,
          houseId: variables.house_id
        })
      });
    }
  });
};
export const useRemoveTenant = () => {
  const queryClient = useQueryClient();
  const { cognitoUser } = useContextHospi();

  return useMutation<unknown, unknown, IBaseMatchData>({
    mutationFn: (payload) => HostAPI.removeTenant(payload),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: getMatchQueryKey({
          studentId: variables.student_id,
          hostId: variables.host_id,
          houseId: variables.house_id
        })
      });
      queryClient.invalidateQueries({
        queryKey: getHouseQueryKey(variables.house_id)
      });
      queryClient.invalidateQueries({
        queryKey: getHousesQueryKey(cognitoUser?.id!)
      });
    }
  });
};
export const useUpdateOfferedDates = () => {
  const queryClient = useQueryClient();
  return useMutation<
    unknown,
    unknown,
    Required<
      Pick<
        IMatch,
        | "host_id"
        | "house_id"
        | "student_id"
        | "student_check_in_date"
        | "student_check_out_date" // TODO: ? make it possible from the backend side to send null if no date
      >
    >
  >({
    mutationFn: (payload) => HostAPI.updateOfferedDates(payload),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: getMatchQueryKey({
          studentId: variables.student_id,
          hostId: variables.host_id,
          houseId: variables.house_id
        })
      });
    }
  });
};

export const getHostMatchesQueryKey = ({ userId }: { userId?: string }) => [
  "hostMatches",
  userId
];

export const useGetHostMatches = (userId?: string, enabled: boolean = true) => {
  return useQuery(
    getHostMatchesQueryKey({ userId }),
    () => {
      if (!userId) return;
      return HostAPI.getHostMatches(userId);
    },
    {
      enabled: Boolean(userId) && enabled !== false
    }
  );
};

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { IMatch, IBaseMatchData } from "../../interfaces/interfaces";
import { StudentAPI } from "../studentApi";
import { getHouseQueryKey, getHousesQueryKey } from "./houseApiQueries";
import { useContextHospi } from "../../context/ContextHospi";

export const useStudentAcceptInvite = () => {
  const queryClient = useQueryClient();
  const { cognitoUser } = useContextHospi();
  return useMutation<unknown, unknown, IBaseMatchData>({
    mutationFn: (payload) => StudentAPI.acceptInvite(payload),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: getStudentMatchesQueryKey({ userId: variables.student_id })
      });
      queryClient.invalidateQueries({
        queryKey: getMatchQueryKey({
          studentId: variables.student_id,
          hostId: variables.host_id,
          houseId: variables.house_id
        })
      });
      queryClient.invalidateQueries({
        queryKey: getHouseQueryKey(variables.house_id)
      });
      queryClient.invalidateQueries({
        queryKey: getHousesQueryKey(cognitoUser?.id!)
      });
    }
  });
};

export const getMatchQueryKey = (params: {
  studentId?: string;
  hostId?: string;
  houseId?: string;
}) => ["match", JSON.stringify(params)];

export const useGetMatch = (
  studentId?: string,
  hostId?: string,
  houseId?: string,
  enabled?: boolean
) => {
  return useQuery(
    getMatchQueryKey({ studentId, hostId, houseId }),
    () => {
      if (!studentId || !hostId || !houseId) {
        return;
      }

      return StudentAPI.getMatch(studentId, hostId, houseId, {
        showNotificationOnError: false
      })
        .then((response) => {
          return response;
        })
        .catch((e) => {
          if (e?.response?.status === 404) {
            return {
              student_id: studentId,
              host_id: hostId,
              house_id: houseId,
              match_status: undefined
            } as IMatch;
          }
          return Promise.reject(e);
        });
    },
    {
      enabled: Boolean(studentId && hostId && houseId) && enabled !== false
    }
  );
};

export const getStudentMatchesQueryKey = ({ userId }: { userId?: string }) => [
  "studentMatches",
  userId
];

export const useGetStudentMatches = (
  userId?: string,
  enabled: boolean = true
) => {
  return useQuery(
    getStudentMatchesQueryKey({ userId }),
    () => {
      if (!userId) {
        return;
      }

      return StudentAPI.getMatches(userId);
    },
    {
      enabled: Boolean(userId) && enabled !== false
    }
  );
};

export const useShowInterest = () => {
  const queryClient = useQueryClient();

  return useMutation<
    unknown,
    unknown,
    { match: IBaseMatchData; message: string }
  >({
    mutationFn: (payload) =>
      StudentAPI.showInterest(payload.match, payload.message),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: getMatchQueryKey({
          studentId: variables.match.student_id,
          hostId: variables.match.host_id,
          houseId: variables.match.house_id
        })
      });
      queryClient.invalidateQueries({
        queryKey: getStudentMatchesQueryKey({
          userId: variables.match.student_id
        })
      });
    }
  });
};

export const useRejectInvite = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, IBaseMatchData>({
    mutationFn: (payload) => StudentAPI.rejectInvite(payload),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries({
        queryKey: getMatchQueryKey({
          studentId: variables.student_id,
          hostId: variables.host_id,
          houseId: variables.house_id
        })
      });
      queryClient.invalidateQueries({
        queryKey: getStudentMatchesQueryKey({
          userId: variables.student_id
        })
      });
    }
  });
};

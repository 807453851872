import {
  add,
  differenceInDays,
  isSameDay,
  isSameWeek,
  isSameYear,
  isValid,
  parse,
  format,
  isPast
} from "date-fns";
import { captureMessage } from "@sentry/react";
import { nl, enGB } from "date-fns/locale";
import { NO_END_DATE } from "./constants";
import { TLang } from "../assets/data/isoNameLanguage";
import isWithinInterval from "date-fns/isWithinInterval";

export const calculateAge = (birthday: Date) => {
  return new Date().getFullYear() - birthday.getFullYear();
};
export const parseServerDate = (date: string): Date => {
  return parse(date, "yyyy-MM-dd", new Date(2000, 0, 1, 0, 0, 0, 0));
};

export const formatToServerDate = (date: Date): string => {
  return format(date, "yyyy-MM-dd");
};

export const parseServerDateTime = (string: string): Date => {
  return new Date(string + "Z");
};

export const parseServerDateOrTime = (string: string): Date => {
  const date = parseServerDate(string);
  if (isValid(date)) {
    return date;
  } else {
    const dateWithTime = new Date(string + "Z");
    if (isValid(dateWithTime)) {
      return dateWithTime;
    } else {
      throw Error(`Date string is not valid: ${string}`);
    }
  }
};
export const formatToServerDateTime = (date: Date): string => {
  return date.toISOString().replace("Z", "000");
};
export const formatAsUTCToServerDateTime = (date: Date): string => {
  return formatToServerDateTime(
    add(date, { minutes: -date.getTimezoneOffset() })
  );
};
export const formatAsUTCToServerDate = (date: Date): string => {
  return formatToServerDate(add(date, { minutes: -date.getTimezoneOffset() }));
};

export const formatDateToShort = (date: Date): string => {
  if (!isValid(date)) {
    captureMessage("Date is not valid");
    return "Invalid date";
  }
  return format(date, "dd MMM yyyy");
};

export const formatDateTime = (
  date: Date,
  { minimizeOutputLength }: { minimizeOutputLength: boolean }
): string => {
  if (!minimizeOutputLength) {
    return format(date, "dd MMM yyyy HH:mm");
  }

  if (isSameDay(new Date(), date)) {
    return format(date, "HH:mm");
  } else if (isSameWeek(new Date(), date)) {
    return format(date, "EEEE HH:mm");
  } else if (!isSameWeek(new Date(), date)) {
    if (isSameYear(new Date(), date)) {
      return format(date, "dd MMM HH:mm");
    }
    return format(date, "dd MMM yyyy HH:mm");
  }

  return format(date, "dd MMM yyyy HH:mm");
};

export const noEndDate = (endDate: Date) => {
  const noEndDateDaysDif = differenceInDays(NO_END_DATE, endDate);

  return noEndDateDaysDif >= -1 && noEndDateDaysDif <= 1;
};

export const dateString = (
  value: string | undefined,
  curLang: TLang,
  isShortMonth?: boolean
): string => {
  if (!value || value === "") return "";
  const dt = new Date(value);

  return `${format(dt, `d MMM${isShortMonth ? "" : "M"}`, {
    locale: curLang === "NL" ? nl : enGB
  })} '${dt.getFullYear().toString().slice(-2)}`;
};

export const isAvailableNow = (
  start?: string,
  end?: string | null
): boolean => {
  if (!start || typeof end === "undefined") return false;
  const startDate = parseServerDateOrTime(start);

  if (isValid(startDate)) {
    if (end === null || end === "") {
      return isPast(startDate);
    } else {
      const endDate = parseServerDateOrTime(end);

      return isValid(endDate)
        ? isWithinInterval(new Date(), {
            start: startDate,
            end: endDate
          })
        : false;
    }
  } else return false;
};

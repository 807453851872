import { IMatch, IBaseMatchData, TMatchData } from "interfaces/interfaces";
import { ServiceApi } from "../utils/utilAmplify";
import { ServiceName } from "../config/amplify";

class HostAPIClass {
  private readonly api: ServiceApi;
  constructor() {
    this.api = new ServiceApi(ServiceName.HostMatches);
  }
  getHostMatches(hostId: string): Promise<TMatchData[]> {
    return this.api.get(`host-matches/${hostId}`);
  }
  invite(
    payload: Required<
      Pick<
        IMatch,
        | "host_id"
        | "house_id"
        | "student_id"
        | "student_check_in_date"
        | "student_check_out_date"
      >
    >
  ) {
    return this.api.post("invite", payload);
  }
  cancelInvite(payload: IBaseMatchData) {
    return this.api.post("cancel-invite", payload);
  }
  removeMatch(payload: IMatch) {
    return this.api.post("remove-match", payload);
  }
  removeTenant(payload: IBaseMatchData) {
    return this.api.post("remove-tenant", payload);
  }
  updateOfferedDates(
    payload: Required<
      Pick<
        IMatch,
        | "host_id"
        | "house_id"
        | "student_id"
        | "student_check_in_date"
        | "student_check_out_date"
      >
    >
  ) {
    return this.api.post("update-offered-dates", payload);
  }
}

export const HostAPI = new HostAPIClass();
